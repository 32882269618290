import axios from 'axios';
// // 通过局部引入方式引入 Element 的 Message 组件功能
// import { Message } from 'element-ui';
// // 引入 qs 用于进行请求参数处理
// import qs from 'qs';
// // 引入 Vuex 的数据
// import store from '@/store';
// // 引入 router
// import router from '@/router';
import CryptoJS from './crypto';

// 请求头加密认证
/* eslint-disable */
function cryptoHeaders(body) {
  const hmac_username = 'AUCTION';
  const hmac_secret = 'a081dca694bbd3280';
  let date; let digest = '';
  let authorization; let sign_string; let sign_digest; let
    sign_base64_digest;
  date = new Date().toGMTString();
  if (body) {
    digest = `SHA-256=${CryptoJS.enc.Base64.stringify(CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(JSON.stringify(body)).toString()))}`;
  }
  sign_string = `X-Date: ${date}\ndigest: ${digest}`;
  sign_digest = CryptoJS.HmacSHA256(sign_string, hmac_secret);
  sign_base64_digest = CryptoJS.enc.Base64.stringify(sign_digest);
  authorization = `hmac username="${hmac_username}", algorithm="hmac-sha256", headers="X-Date digest", signature="${sign_base64_digest}"`;

  return {
    'X-Date': date,
    Digest: digest,
    Authorization: authorization,
  };
}

// create 创建 axios 实例
const request = axios.create({
  // timeout: 2000
  // 拼接process.env.VUE_APP_BASE_API
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/api',
  // headers:
});

// 请求拦截器
request.interceptors.request.use((config) => {
  // 判断 config.url 的前缀，来进行请求 baseURL 的设置
  // eslint-disable-next-line no-param-reassign
  console.log(config);
  // 统一设置 Token 信息
  // const { user } = store.state;
  // if (user && user.access_token) {
  //   // eslint-disable-next-line no-param-reassign
  //   config.headers.Authorization = user.access_token;
  // }
  // eslint-disable-next-line no-nested-ternary
  Object.assign(config.headers, cryptoHeaders(config.data ? config.data : ''));
  return config;
});

// 响应拦截器
request.interceptors.response.use((response) => {
  // 状态码 2xx 会执行这里
  console.log('响应成功了：', response);
  return response;
}, (error) => {
  console.log('响应失败了：', error);
  // 将本次请求的错误对象继续向后抛出，让接收响应的处理函数进行操作
  return Promise.reject(error);
});

export default request;
