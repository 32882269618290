<template>
  <el-container v-title data-title="小茶书行情">
    <el-header>
      <img class="logo" src="@/assets/logo.png" alt="">
      <p class="title">小茶书行情</p>
      <img class="code" src="@/assets/code.jpeg" alt="">
      <div class="tea-title"
      :class="select == 1 ? 'select-title' : ''"
      @click="changeSelect(1)"><p>茶行情</p></div>
      <div class="china-title"
      :class="select == 0 ? 'select-title' : ''"
      @click="changeSelect(0)"><p>瓷器行情</p></div>
      <div class="drink-title"
      :class="select == 2 ? 'select-title' : ''"
      @click="changeSelect(2)"><p>酒行情</p></div>
    </el-header>
    <el-main>
      <div class="main-title">
        <p
        class="text"
        v-for="value in ['品名', '参考价', '涨跌额', '涨跌幅', '品牌', '年份', !select ? '款识' : '规格']"
        :key="value">{{ value }}</p>
      </div>
      <vue-seamless-scroll
        :data="CardPartsStatisticsList"
        class="seamless-warp"
        :class-option="classOption"
        @ScrollEnd="ScrollEnd"
        ref="seamlessScroll">
        <ul>
           <li class="DataList_top"
           v-for="(item) in CardPartsStatisticsList"
           :key="item.id">
              <div class="main-list">
                <div class="name">
                  <p class="cover-img"
                  :style="{'background-image': 'url(' + item.cover_img + ')'}"></p>
                  <p class="short_name">{{item.short_name}}</p>
                </div>
                <p class="text price">¥{{item.price_show}}</p>
                <p class="text amount"
                :style="{color: item.change_amount_color}">{{item.change_amount_show}}</p>
                <p class="text scope"
                :style="{color: item.change_amount_color}">{{item.change_amount_scope_show}}%</p>
                <p class="text brand">{{item.brand}}</p>
                <p class="text date">{{item.produce_date ? item.produce_date : '无'}}</p>
                <p class="text last" v-if="select !== 0">{{item.specs ? item.specs : '无'}}</p>
                <p class="text last" v-else>{{item.knowledge ? item.knowledge : '无'}}</p>
              </div>
            </li>
        </ul>
      </vue-seamless-scroll>
    </el-main>
  </el-container>
</template>
<script>
import vueSeamlessScroll from 'vue-seamless-scroll';
import getQuotationRequest from '@/services/quotation';

export default {
  name: 'home',
  data() {
    return {
      select: 1,
      CardPartsStatisticsList: [],
    };
  },
  created() {
    this.loadQuotation();
  },
  components: {
    vueSeamlessScroll,
  },
  methods: {
    changeSelect(e) {
      this.select = e;
      this.loadQuotation();
    },
    ScrollEnd() {
      console.log('ScrollEnd');
      this.loadQuotation();
    },
    async loadQuotation() {
      // eslint-disable-next-line no-nested-ternary
      const { data } = await getQuotationRequest(this.select === 0 ? 1 : this.select === 2 ? 2 : 0);
      console.log('查询行情动态成功', data);
      if (data.code === 200) {
        data.data.quotation_goods.forEach((value) => {
          value.price_show = this.changeNumberFormat(value.price);
          if (value.change_amount) {
            value.change_amount_show = `${value.change_amount > 0 ? '↑' : '↓'}¥${this.changeNumberFormat(Math.abs(value.change_amount))}`;
            value.change_amount_color = value.change_amount > 0 ? '#FF0000' : '#2DB956';
            value.change_amount_scope_show = (value.change_amount > 0 ? '+' : '-') + Math.abs(parseFloat(value.change_amount_scope.replace('%', ''))).toFixed(2);
          } else {
            value.change_amount_show = '-';
            value.change_amount_color = '#8c8c8c';
            value.change_amount_scope_show = '0.00';
          }
        });
        this.CardPartsStatisticsList = data.data.quotation_goods;
        console.log('查询行情动态成功', this.CardPartsStatisticsList);
        this.$refs.seamlessScroll.reset();
      }
    },
    /* eslint-disable */
    // 改变数字格式
    changeNumberFormat(s) {
      if (s == null || s == "")
        return "0";
      s = s.toString().replace(/[\r,]/g, "")
      // test 如果 s 中含有与 /[^0-9\.]/ 匹配的文本，则返回 true，否则返回 false。
      // 判断字符串是否全部由数字与.组成
      if (/[^0-9\.]/.test(s))
        return "0";
      if (s == null || s == "")
        return "0";
      // $1、$2、……表示正则表达式里面第一个、第二个、……括号里面的匹配内容, \d:表示匹配的是数字 \d+:表示1个或多个数字 \d*：表示0个或多个数字
      // 如果全部是数字，把所有数字替换成数字'.' 也就是如果都是数字就在最后加个'.'
      s = s.toString().replace(/^(\d*)$/, "$1.");
      // 先在最后添加00，之后只保留两位小数
      s = (s + "00").replace(/(\d*\.\d\d)\d*/, "$1");
      // 把"."替换为","
      s = s.replace(".", ",");
      // 两种正则格式  第一种是数字 1，第二种是 数字数字数字"," 1000, \d{3}代表循环三次\d
      var re = /(\d)(\d{3},)/;
      // 判断字符串中是否包含四个数字一个逗号的情况 1000,
      while (re.test(s))
        s = s.replace(re, "$1,$2") // 包含四个数字一个逗号的情况时，替换为 数字 "," 数字数字数字","     1000,00 —— 1,000,00
      s = s.replace(/,(\d\d)$/, ".$1"); // 把最后换成小数点 1,000,00 —— 1,000.00
      // 以"."拆分成数组
      var a = s.split(".");
      // 去掉此处添加的小数部分
      if (a[1] == "00") {
        s = a[0];
      }
      return s;
    }
  /* eslint-enable */
  },
  computed: {
    classOption() {
      return {
        step: 1, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
};
</script>

<style lang="less" scoped>
@scale: (1px);
.el-header {
  position: relative;
  min-width: 500px;
  height: 120 * @scale !important;
  background-color: #00c8b6;
  .logo {
    position: absolute;
    width: 36 * @scale;
    height: 36 * @scale;
    border-radius: 18 * @scale;
    left: 64 * @scale;
    top: 14 * @scale;
  }
  .title {
    position: absolute;
    left: 110 * @scale;
    top: 22 * @scale;
    font-size: 20 * @scale;
    line-height: 20 * @scale;
    font-weight: 400;
    color: #FFFFFF;
  }
  .code {
    position: absolute;
    right: 60 * @scale;
    top: 15 * @scale;
    width: 80 * @scale;
    height: 80 * @scale;
    background: #FFFFFF;
    border-radius: 50%;
    padding: 5 * @scale;
  }
  .tea-title {
    position: absolute;
    text-align: center;
    bottom: 0;
    left: 64 * @scale;
    width: 190 * @scale;
    height: 50 * @scale;
    border-radius: 12 * @scale 12 * @scale 0 * @scale 0 * @scale;
    p {
      display: inline-block;
      margin: 0 auto;
      text-align: center;
      font-size: 26 * @scale;
      font-weight: bold;
      color: #fff;
      line-height: 48 * @scale;
      border-bottom: 2 * @scale #00c8b6 solid;
      box-sizing: border-box;
    }
  }
  .china-title {
    position: absolute;
    text-align: center;
    bottom: 0;
    left: 254 * @scale;
    width: 190 * @scale;
    height: 50 * @scale;
    border-radius: 12 * @scale 12 * @scale 0 * @scale 0 * @scale;
    p {
      display: inline-block;
      margin: 0 auto;
      text-align: center;
      font-size: 26 * @scale;
      font-weight: bold;
      color: #fff;
      line-height: 48 * @scale;
      border-bottom: 2 * @scale #00c8b6 solid;
      box-sizing: border-box;
    }
  }
  .drink-title {
    position: absolute;
    text-align: center;
    bottom: 0;
    left: 444 * @scale;
    width: 128 * @scale;
    height: 50 * @scale;
    border-radius: 12 * @scale 12 * @scale 0 * @scale 0 * @scale;
    p {
      display: inline-block;
      margin: 0 auto;
      text-align: center;
      font-size: 26 * @scale;
      font-weight: bold;
      color: #fff;
      line-height: 48 * @scale;
      border-bottom: 2 * @scale #00c8b6 solid;
      box-sizing: border-box;
    }
  }
  .select-title {
    background: #FFFFFF;
    p {
      color: #00c8b6;
    }
  }
}

.el-main {
  min-width: 500px;
  height: calc(100vh - 120 * @scale);
  .main-title {
    display: block;
    height: 80 * @scale;
    margin-left: 64 * @scale;
    margin-right: 50 * @scale;
    box-sizing: border-box;
    border-bottom: 2 * @scale #f5f5f5 solid;
    p {
      display: inline-block;
      width: calc(100% / 10);
      text-align: center;
      font-size: 26 * @scale;
      line-height: 80 * @scale;
      font-weight: 400;
      color: #8C8C8C;
      box-sizing: border-box;
    }
  }
  .main-title :first-child {
    width: calc(100% / 10 * 3);
    text-align: left;
  }
  .main-title :last-child {
    width: calc(100% / 10 * 2);
  }
  .seamless-warp{
    width: 100%;
    height: calc(100% - 80 * @scale);
    overflow: hidden;
  }
  .main-list {
    position: relative;
    display: block;
    height: 108 * @scale;
    margin-left: 64 * @scale;
    margin-right: 50 * @scale;
    box-sizing: border-box;
    border-bottom: 2 * @scale #f5f5f5 solid;
    .name {
      display: inline-block;
      width: calc(100% / 10 * 3);
      .cover-img {
        position: absolute;
        top: 19 * @scale;
        width: 70 * @scale;
        height: 70 * @scale;
        background-size: cover;
        background-position: center;
      }
      .short_name {
        text-align: left;
        margin-left: 84 * @scale;
        font-size: 24 * @scale;
        line-height: 108 * @scale;
        font-weight: 400;
        color: #333333;
        max-width: calc((100vw - 64 * @scale - 50 * @scale) / 10 * 3 - 84 * @scale);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .text {
      display: inline-block;
      width: calc(100% / 10 * 1);
      font-size: 24 * @scale;
      line-height: 108 * @scale;
      font-weight: 400;
      color: #333333;
      text-align: center;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .last {
      width: calc(100% / 10 * 2);
    }
  }
}

</style>
